// import $ from 'jquery';
import Houdini from 'houdinijs';
import stickyFooter from 'sticky-footer';
import SmoothScroll from 'smooth-scroll';
import Tabby from 'tabbyjs';
import headroomJS from "headroom.js";
import Noty from "noty";
import LazyLoad from "vanilla-lazyload";
import 'bootstrap/js/src/dropdown';
import fancybox from '@fancyapps/fancybox';
import addBrowserClass from './modules/browser';
import smallDevice from './modules/small_device';
import tippy from 'tippy.js';
import sal from "sal.js";
// import vdt from './modules/jq-vertical-dot-nav';
import fontSetting from './modules/font';
import Chart from 'chart.js';
import bsCustomFileInput from 'bs-custom-file-input';

// -------------------------------------------------------------------
// HoudiniJS
// -------------------------------------------------------------------
var disclosure = new Houdini('[data-houdini-group]', {
    isAccordion: true
});
// -------------------------------------------------------------------
// stickyFooter JS
// -------------------------------------------------------------------
stickyFooter.init({
    selectorWrap: '[data-sticky-wrap]', // Selector for the wrap container (must use a valid CSS selector)
    selectorFooter: '[data-sticky-footer]', // Selector for the footer (must use a valid CSS selector)
    callback: function() {}, // Runs after the footer is stuck
});
// -------------------------------------------------------------------
// SmoothScroll JS
// -------------------------------------------------------------------
const scroll = new SmoothScroll('a[href*="#"]', {
    header: '.l-header',
    speed: 50,
    speedAsDuration: false
});
// scroll.animateScroll(80);
// -------------------------------------------------------------------
// Tippy.js
// -------------------------------------------------------------------
new tippy('.js-tippy', {
    theme: 'org',
    arrow: true,
    hideOnClick: true,
    arrowType: 'round', // or 'sharp' (default)
    interactive: false,
    maxWidth: '30em'
});
// -------------------------------------------------------------------
// Tabby JS
// -------------------------------------------------------------------
if ($('[data-tabs]').length) {
    const tabs = new Tabby('[data-tabs]');
} else {}
if ($('[data-tabsBd]').length) {
    const tabsBd = new Tabby('[data-tabsBd]');
} else {}
if ($('[data-tabsBg]').length) {
    const tabsBd = new Tabby('[data-tabsBg]');
} else {}

// -------------------------------------------------------------------
// LazyLoad
// -------------------------------------------------------------------
const lazyContent = new LazyLoad({
    elements_selector: "img.lazy",
    use_native: true // <-- there you go
});

const lazyBackground = new LazyLoad({
    elements_selector: "iframe.lazy, video.lazy, div.lazy"
        // DON'T PASS use_native: true HERE
});

// -------------------------------------------------------------------
// Sal JS
// -------------------------------------------------------------------
sal();

// // -------------------------------------------------------------------
// //
// // jQuery
// //
// // -------------------------------------------------------------------
// $(function() {
//     // ------------------------
//     //
//     // Vertical Dot Nav
//     //
//     // ------------------------
//     if ($('.js-section').length) {
//         $('.js-section').verticalDotNav();
//     } else {}
//     // ------------------------
//     //
//     // Accordion
//     //
//     // ------------------------
//     $(".js-trigger").on("click", function() {
//         $(this).next().slideToggle("fast");
//         $("html,body").animate();
//         if ($(this).hasClass("active")) { // クリックされた要素がclickedクラスだったら
//             $(this).removeClass("active");
//         } else {
//             $(this).addClass("active");
//         }
//     });
// });